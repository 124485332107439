import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../components/shared/Spinner';
import ProfileItem from './ProfileItem';
import { getProfiles } from '../../actions/profile';
import MainPage from '../mainpage/MainPage';

const Profiles = ({ getProfiles, profile: { profiles, loading } }) => {
  useEffect(() => {
    getProfiles();
  }, [getProfiles]);

  return (
    <MainPage>
      <section className='container'>
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <h1 className='large text-primary'>Members</h1>
            <p className='lead'>
              <i className='fab fa-connectdevelop' /> Browse and connect with
              members
            </p>
            <div className='profiles'>
              {profiles && profiles.length > 0 ? (
                profiles.map((profile) => (
                  <ProfileItem key={profile._id} profile={profile} />
                ))
              ) : (
                <h4 className='text-danger'>No profiles found...</h4>
              )}
            </div>
          </Fragment>
        )}
      </section>
    </MainPage>
  );
};

Profiles.propTypes = {
  getProfiles: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

// const mapStateToProps = (state) => ({
//   profile: state.profile,
// });

const mapStateToProps = (state) => {
  //console.log(state.profile);
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, { getProfiles })(Profiles);
