import React, { Fragment, useState, useEffect } from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MainPage from '../mainpage/MainPage';
import { createProfile, getCurrentProfile } from '../../actions/profile';
import Alert from '../shared/Alert';

const initialState = {
  position: '',
  company: '',
  website: '',
  location: '',
  gender: '',
  dob: '',
  phone: '',
  skills: '',
  bio: '',
  title: '',
  category: '',
  type: '',
  from: '',
  to: '',
  current: '',
  active: '',
  twitter: '',
  facebook: '',
  linkedin: '',
  youtube: '',
  instagram: '',
};

const ProfileForm = ({
  profile: { profile, loading },
  createProfile,
  getCurrentProfile,
}) => {
  const [formData, setFormData] = useState(initialState);

  const creatingProfile = useMatch('/create-profile');

  const [displaySocialInputs, toggleSocialInputs] = useState(false);

  const navigate = useNavigate();

  const formatDatetoPopulate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().slice(0, 10);
  };

  useEffect(() => {
    // if there is no profile, attempt to fetch one
    if (!profile) getCurrentProfile();

    // if we finished loading and we do have a profile
    // then build our profileData
    if (!loading && profile) {
      // Converts ISO 8601 date string to HTML date input format (YYYY-MM-DD)

      // Initialize profileData with initialState
      const profileData = { ...initialState };

      for (const key in profile) {
        if (key in profileData) {
          // Populate date of birth with the response data
          profileData.dob = profile.dob
            ? formatDatetoPopulate(profile.dob)
            : '';
          profileData[key] = profile[key];
        }
      }
      for (const key in profile.social) {
        if (key in profileData) profileData[key] = profile.social[key];
      }

      // for (const key in profile.membership) {
      //   if (key in profileData) {
      //     // Populate from and to date with the response data
      //     profileData.from = profile.membership?.from
      //       ? formatDatetoPopulate(profile.membership.from)
      //       : '';
      //     profileData.to = profile.membership?.to
      //       ? formatDatetoPopulate(profile.membership.to)
      //       : '';
      //     profileData[key] = profile.membership[key];
      //   }
      // }

      for (const membershipItem of profile.membership) {
        if (membershipItem.current) {
          // Populate from and to date with the response data
          profileData.from = membershipItem.from
            ? formatDatetoPopulate(membershipItem.from)
            : '';
          profileData.to = membershipItem.to
            ? formatDatetoPopulate(membershipItem.to)
            : '';

          const updatedFromDate = profileData.from;
          const updatedToDate = profileData.to;
          const updatedMembershipItem = Object.assign({}, membershipItem, {
            from: updatedFromDate,
            to: updatedToDate,
          });

          // console.log('From profileData', profileData);
          // console.log('From membershipItem', updatedMembershipItem);

          Object.assign(profileData, updatedMembershipItem);
          break; // Exit the loop since we found the current membership
        } else if (
          !profileData.to ||
          (profileData.to &&
            new Date(profileData.to) < new Date(membershipItem.to))
        ) {
          profileData.from = membershipItem.from
            ? formatDatetoPopulate(membershipItem.from)
            : '';
          profileData.to = membershipItem.to
            ? formatDatetoPopulate(membershipItem.to)
            : '';

          const updatedFromDate = profileData.from;
          const updatedToDate = profileData.to;
          const updatedMembershipItem = Object.assign({}, membershipItem, {
            from: updatedFromDate,
            to: updatedToDate,
          });

          // console.log('To profileData', profileData);
          // console.log('To membershipItem', updatedMembershipItem);

          Object.assign(profileData, updatedMembershipItem);
        }
      }

      // the skills may be an array from our API response
      if (Array.isArray(profileData.skills))
        profileData.skills = profileData.skills.join(', ');

      // the membership types may be an array from our API response
      if (Array.isArray(profileData.types))
        profileData.types = profileData.types.join(', ');

      //console.log(profileData);

      // set local state with the profileData
      setFormData(profileData);
    }
  }, [loading, getCurrentProfile, profile]);

  const {
    position,
    company,
    website,
    location,
    gender,
    dob,
    phone,
    skills,
    bio,
    title,
    category,
    type,
    from,
    to,
    current,
    active,
    twitter,
    facebook,
    linkedin,
    youtube,
    instagram,
  } = formData;

  const genderTypes = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Other' },
    { value: 'unknown', label: 'Prefer not to say' },
  ];

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      if (value === '' || /^[0-9\b]{0,10}$/.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const onSubmit = (e) => {
    const editing = profile ? true : false;
    e.preventDefault();

    // Validation for required fields
    const requiredFields = [
      'position',
      'gender',
      'dob',
      'phone',
      'skills',
      'title',
      'category',
      'type',
      'from',
    ];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    createProfile(formData, editing).then(() => {
      if (!editing && !missingFields.length > 0) navigate('/dashboard');
    });
  };

  return (
    <MainPage>
      <section className='container'>
        <h1 className='large text-primary'>
          {creatingProfile ? 'Create Your Profile' : 'Edit Your Profile'}
        </h1>
        <p className='lead'>
          <i className='fas fa-user' />
          {creatingProfile
            ? ` Let's get some information to make your profile awesome`
            : ' Add some changes to your profile'}
        </p>
        <form className='form' onSubmit={onSubmit}>
          <div className='form-group'>
            <select name='position' value={position} onChange={onChange}>
              <option value='' disabled>
                * Select your professional role
              </option>
              <option value='Manager'>Manager</option>
              <option value='Engineer'>Engineer</option>
              <option value='Developer'>Developer</option>
              <option value='Teacher'>Teacher</option>
              <option value='Student'>Student</option>
              <option value='Housewife'>Housewife</option>
              <option value='Intern'>Intern</option>
              <option value='Other'>Other</option>
            </select>
            <small className='form-text'>
              Give us an idea of where you are at in your career
            </small>
          </div>
          <div className='form-group'>
            <input
              type='text'
              placeholder='Company'
              name='company'
              value={company}
              onChange={onChange}
            />
            <small className='form-text'>
              Could be your own company or one you work for
            </small>
          </div>
          <div className='form-group'>
            <input
              type='text'
              placeholder='Website'
              name='website'
              value={website}
              onChange={onChange}
            />
            <small className='form-text'>
              Could be your own or a company website (eg.
              www.chicagolandtharusociety.org)
            </small>
          </div>
          <div className='form-group'>
            <input
              type='text'
              placeholder='Location'
              name='location'
              value={location}
              onChange={onChange}
            />
            <small className='form-text'>
              City & state suggested (eg. Chicago, IL)
            </small>
          </div>
          <div className='form-group'>
            <fieldset>
              <legend className='form-text'> Select your gender</legend>
              {genderTypes.map((item) => (
                <span key={item.value}>
                  <input
                    type='radio'
                    name='gender'
                    value={item.value}
                    id={item.value}
                    checked={gender === item.value}
                    onChange={onChange}
                  />
                  <label className='form-label' htmlFor={item.value}>
                    {item.label}
                  </label>
                </span>
              ))}
            </fieldset>
          </div>
          <div className='form-group'>
            <small className='form-text'>Date Of Birth</small>
            <input type='date' name='dob' value={dob} onChange={onChange} />
          </div>
          <div className='form-group'>
            <input
              type='tel'
              placeholder='* Phone'
              name='phone'
              value={phone}
              onChange={onChange}
              pattern='[0-9]{10}'
            />
            <small className='form-text'> Phone (10-digit number only)</small>
          </div>
          <div className='form-group'>
            <input
              type='text'
              placeholder='* Skills'
              name='skills'
              value={skills}
              onChange={onChange}
            />
            <small className='form-text'>
              Please use comma separated values (eg.
              HTML,CSS,JavaScript,Teaching, Cooking)
            </small>
          </div>
          <div className='form-group'>
            <textarea
              placeholder='A short bio of yourself'
              name='bio'
              value={bio}
              onChange={onChange}
            />
            <small className='form-text'>Tell us a little about yourself</small>
          </div>

          <div className='my-2'>
            <h1 className='lead text-primary'>
              {creatingProfile ? 'Add Membership Info' : 'Edit Membership Info'}
            </h1>
          </div>

          <Fragment>
            <div className='form-group'>
              <select name='title' value={title} onChange={onChange}>
                <option value='' disabled>
                  * Select your title
                </option>
                <option value='President'>President</option>
                <option value='Vice PresidentI'>Vice President I</option>
                <option value='Vice PresidentII'>Vice President II</option>
                <option value='Vice PresidentIII'>Vice President III</option>
                <option value='General Secretary'>General Secretary</option>
                <option value='Secretary'>Secretary</option>
                <option value='Treasurer'>Treasurer</option>
                <option value='Board Member'>Board Member</option>
                <option value='General(Well-Wisher)'>
                  General(Well-Wisher)
                </option>
              </select>
              <small className='form-text'>
                Provide your title that you belong in the community
              </small>
            </div>

            <div className='form-group'>
              <select name='category' value={category} onChange={onChange}>
                <option value='' disabled>
                  * Select your category
                </option>
                <option value='Founder Member'>Founder Member </option>
                <option value='Founder Member'>Co-Founder Member </option>
                <option value='Executive Member'>Executive Member</option>
                <option value='Advisory Member'>Advisory Member</option>
                <option value='General Member'>General Member</option>
              </select>
              <small className='form-text'>
                Provide your member category that you belong in the community
              </small>
            </div>

            <div className='form-group'>
              <select name='type' value={type} onChange={onChange}>
                <option value='' disabled>
                  * Select your membership type
                </option>
                <option value='Founder Member'>Lifetime Membership </option>
                <option value='General Member'>General Membership</option>
              </select>
              <small className='form-text'>
                Provide your memberhip type that you belong in the community
              </small>
            </div>

            <div className='form-group'>
              <small className='form-text'>From Date</small>
              <input type='date' name='from' value={from} onChange={onChange} />
            </div>
            <div className='form-group'>
              <p>
                <input
                  type='checkbox'
                  name='current'
                  checked={current}
                  value={current}
                  onChange={() => {
                    setFormData({ ...formData, current: !current });
                  }}
                />
                <label className='form-label'>Current </label>
              </p>
            </div>
            <div className='form-group'>
              <small className='form-text'>To Date</small>
              <input
                type='date'
                name='to'
                value={to}
                onChange={onChange}
                disabled={current}
              />
            </div>

            {/* <div className='form-group'>
              <p>
                <input
                  type='checkbox'
                  name='active'
                  checked={active}
                  value={active}
                  onChange={() => {
                    setFormData({ ...formData, active: !active });
                  }}
                />
                <label className='form-label'>Active Membership </label>
              </p>
            </div> */}
          </Fragment>

          <div className='my-2'>
            <button
              onClick={() => toggleSocialInputs(!displaySocialInputs)}
              type='button'
              className='btn btn-primary'
            >
              Add Social Network Links
            </button>
            <span>Optional</span>
          </div>

          {displaySocialInputs && (
            <Fragment>
              <div className='form-group social-input'>
                <i className='fab fa-twitter fa-2x' />
                <input
                  type='text'
                  placeholder='Twitter URL'
                  name='twitter'
                  value={twitter}
                  onChange={onChange}
                />
              </div>

              <div className='form-group social-input'>
                <i className='fab fa-facebook fa-2x' />
                <input
                  type='text'
                  placeholder='Facebook URL'
                  name='facebook'
                  value={facebook}
                  onChange={onChange}
                />
              </div>

              <div className='form-group social-input'>
                <i className='fab fa-youtube fa-2x' />
                <input
                  type='text'
                  placeholder='YouTube URL'
                  name='youtube'
                  value={youtube}
                  onChange={onChange}
                />
              </div>

              <div className='form-group social-input'>
                <i className='fab fa-linkedin fa-2x' />
                <input
                  type='text'
                  placeholder='Linkedin URL'
                  name='linkedin'
                  value={linkedin}
                  onChange={onChange}
                />
              </div>

              <div className='form-group social-input'>
                <i className='fab fa-instagram fa-2x' />
                <input
                  type='text'
                  placeholder='Instagram URL'
                  name='instagram'
                  value={instagram}
                  onChange={onChange}
                />
              </div>
            </Fragment>
          )}

          <input type='submit' className='btn btn-primary my-1' />
          <Link className='btn btn-secondary my-1' to='/dashboard'>
            Go Back
          </Link>
        </form>
        <span>
          <Alert />
        </span>
      </section>
    </MainPage>
  );
};

ProfileForm.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { createProfile, getCurrentProfile })(
  ProfileForm
);
