import React from 'react';

import './Footer.css';
const Footer = () => {
  return (
    <div className='footer-grid'>
      <div className='box1'>
        <h2>Local Organization</h2>
        <p>Chicagoland Nepali Friendship Society</p>
        <p>Nepalease Aid</p>
      </div>
      <div className='box2'>
        <h2>Social Organization</h2>
        <p>Chicagoland Tharu Society</p>
        <p>Nepalease Tharu Aid</p>
      </div>
      <div className='box3'>
        <h2>Contact</h2>
        <p>Email: usatharus@gmail.com</p>
      </div>
      <div className='box4'>
        <p>Copyright © CTS</p>
      </div>
    </div>
  );
};

export default Footer;
