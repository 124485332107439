import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

const GuestLinks = ({ menuOpen }) => {
  return (
    <Fragment>
      <ul className={menuOpen ? 'open' : ''}>
        <li>
          <NavLink to='/'>Home</NavLink>
        </li>
        <li>
          <NavLink to='/about'>About</NavLink>
        </li>
        <li>
          <NavLink to='/bylaws'>Bylaws</NavLink>
        </li>
        <li>
          <NavLink to='/calendar'>Calendar</NavLink>
        </li>
        <li>
          <NavLink to='/profiles'>Members</NavLink>
        </li>
        <li>
          <NavLink to='/register'>Register</NavLink>
        </li>
        <li>
          <NavLink to='/login'>Login</NavLink>
        </li>
      </ul>
    </Fragment>
  );
};

export default GuestLinks;
