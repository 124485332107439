import React from 'react';
import MainPage from '../mainpage/MainPage';

const About = () => {
  return (
    <MainPage>
      <h1>This is About Page</h1>
    </MainPage>
  );
};

export default About;
