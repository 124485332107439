import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Home from './components/home/Home';
import CalendarScreen from './components/calendar/CalendarScreen';
import About from './components/about/About';
import Bylaws from './components/bylaws/Bylaws';
import Landing from './components/landingpage/Landing';
import Dashboard from './components/dashboard/Dashboard';
import ProfileForm from './components/profile-forms/ProfileForm';
import Profiles from './components/Profiles/Profiles';
import Profile from './components/profile/Profile';
import AddExperience from './components/profile-forms/AddExperience';
import AddEducation from './components/profile-forms/AddEducation';
import Posts from './components/posts/Posts';
import Post from './components/post/Post';
import NotFound from './components/shared/NotFound';
import PrivateRoute from './components/routing/PrivateRoute';
import { LOGOUT } from './actions/types';
import ManageUsers from './components/admin/ManageUsers';

// Redux
import { Provider } from 'react-redux';
import store from './store/store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import './App.css';
import AddMembership from './components/profile-forms/AddMembership';

const App = () => {
  useEffect(() => {
    // check for token in LS when app first runs
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Routes>
            {/* <Route path='/' element={<Landing />} /> */}
            <Route path='/' element={<Home />} />
            <Route path='/bylaws' element={<Bylaws />} />
            <Route path='/about' element={<About />} />
            <Route path='/calendar' element={<CalendarScreen />} />

            <Route path='/register' element={<Register />} />
            <Route path='/login' element={<Login />} />
            <Route path='profiles' element={<Profiles />} />
            <Route path='profile/:id' element={<Profile />} />
            <Route
              path='dashboard'
              element={<PrivateRoute component={Dashboard} />}
            />
            <Route
              path='create-profile'
              element={<PrivateRoute component={ProfileForm} />}
            />
            <Route
              path='edit-profile'
              element={<PrivateRoute component={ProfileForm} />}
            />
            <Route
              path='add-membership'
              element={<PrivateRoute component={AddMembership} />}
            />
            <Route
              path='add-experience'
              element={<PrivateRoute component={AddExperience} />}
            />
            <Route
              path='add-education'
              element={<PrivateRoute component={AddEducation} />}
            />
            <Route path='posts' element={<PrivateRoute component={Posts} />} />
            <Route
              path='posts/:id'
              element={<PrivateRoute component={Post} />}
            />
            <Route
              path='admin/users'
              element={<PrivateRoute component={ManageUsers} />}
            />
            <Route path='/*' element={<NotFound />} />
          </Routes>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
