import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

const AuthLinks = ({ logout, menuOpen }) => {
  return (
    <Fragment>
      <ul className={menuOpen ? 'open' : ''}>
        <li>
          <NavLink to='/'>Home</NavLink>
        </li>
        <li>
          <NavLink to='/about'>About</NavLink>
        </li>
        <li>
          <NavLink to='/bylaws'>Bylaws</NavLink>
        </li>
        <li>
          <NavLink to='/calendar'>Calendar</NavLink>
        </li>
        <li>
          <NavLink to='/profiles'>Members</NavLink>
        </li>
        <li>
          <NavLink to='/posts'>Posts</NavLink>
        </li>
        <li>
          <NavLink to='/dashboard'>
            <i className='fas fa-user'></i>{' '}
            <span /*className='hide-sm'*/>Profile</span>
          </NavLink>
        </li>
        <li>
          <NavLink to='/login' onClick={logout}>
            <i className='fas fa-sign-out-alt'></i>{' '}
            <span /*className='hide-sm'*/>Logout</span>
          </NavLink>
        </li>
      </ul>
    </Fragment>
  );
};

export default AuthLinks;
