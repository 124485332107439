import React, { Fragment } from 'react';

import Sidebar from '../sidebar/Sidebar';
import Footer from '../footer/Footer';
import Header from '../header/Header';

import './MainPage.css';

const MainPage = ({ children }) => {
  return (
    <Fragment>
      <div className='layout'>
        <div className='header'>
          <Header />
        </div>
        <div className='main'>{children}</div>
        <div className='sidebar'>
          <Sidebar />
        </div>
        <div className='footer'>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

export default MainPage;
