import React from 'react';
import MainPage from '../mainpage/MainPage';

const Bylaws = () => {
  return (
    <MainPage>
      <h1>This is Bylaws Page</h1>;
    </MainPage>
  );
};

export default Bylaws;
