import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../../components/shared/Spinner';
import ProfileTop from './ProfileTop';
import ProfileAbout from './ProfileAbout';
import ProfileExperience from './ProfileExperience';
import ProfileEducation from './ProfileEducation';
import { getProfileById } from '../../actions/profile';
import MainPage from '../mainpage/MainPage';
import ProfileMembership from './ProfileMembership';

const Profile = ({ getProfileById, profile: { profile }, auth }) => {
  const { id } = useParams();
  useEffect(() => {
    getProfileById(id);
  }, [getProfileById, id]);

  return (
    <MainPage>
      <section className='container'>
        {profile === null ? (
          <Spinner />
        ) : (
          <Fragment>
            <Link to='/profiles' className='btn btn-secondary'>
              Back To Profiles
            </Link>
            {auth.isAuthenticated &&
              auth.loading === false &&
              auth.user._id === profile.user._id && (
                <Link to='/edit-profile' className='btn btn-primary'>
                  Edit Profile
                </Link>
              )}
            <div className='profile-grid my-1'>
              <ProfileTop profile={profile} />
              <ProfileAbout profile={profile} />

              <div className='profile-memb bg-white p-2'>
                <h2 className='text-primary'>Membership History Info</h2>
                {profile.membership.length > 0 ? (
                  <Fragment>
                    {profile.membership.map((membership) => (
                      <ProfileMembership
                        key={membership._id}
                        membership={membership}
                      />
                    ))}
                  </Fragment>
                ) : (
                  <h4>No membership details</h4>
                )}
              </div>

              <div className='profile-exp bg-white p-2'>
                <h2 className='text-primary'>Experience</h2>
                {profile.experience.length > 0 ? (
                  <Fragment>
                    {profile.experience.map((experience) => (
                      <ProfileExperience
                        key={experience._id}
                        experience={experience}
                      />
                    ))}
                  </Fragment>
                ) : (
                  <h4>No experience details</h4>
                )}
              </div>

              <div className='profile-edu bg-white p-2'>
                <h2 className='text-primary'>Education</h2>
                {profile.education.length > 0 ? (
                  <Fragment>
                    {profile.education.map((education) => (
                      <ProfileEducation
                        key={education._id}
                        education={education}
                      />
                    ))}
                  </Fragment>
                ) : (
                  <h4>No education details</h4>
                )}
              </div>
            </div>
          </Fragment>
        )}
      </section>
    </MainPage>
  );
};

Profile.propTypes = {
  getProfileById: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, { getProfileById })(Profile);
