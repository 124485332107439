import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MainPage from '../mainpage/MainPage';
import ctsLogo from '../../assests/images/ctslogo.jpeg';
import './Landing.css';

const Landing = ({ isAuthenticated }) => {
  // if (isAuthenticated) {
  //   return <Navigate to='/dashboard' />;
  // }

  return (
    <MainPage>
      <section className='landing'>
        <div className='dark-overlay'>
          <div className='landing-inner'>
            <h1>
              <img src={ctsLogo} alt='CTS Logo' className='logo ' />
            </h1>
            <h1 className='x-large'>Chicagoland Tharu Society</h1>
            <p className='lead'>
              Become CTS membership. You can create portfolio, share posts, and
              get help from other members and make CTS community Awesome.
            </p>
            <div className='buttons'>
              <Link to='/register' className='btn btn-primary'>
                Sign Up
              </Link>
              <Link to='/login' className='btn btn-secondary'>
                Login
              </Link>
            </div>
          </div>
        </div>
      </section>
    </MainPage>
  );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Landing);
